/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes, { func } from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import moment from "moment";
import favIcon from "../images/subtitles_love_fav.png";

function getKeywords(keywords = null) {
  return keywords != null ? keywords.split(",") : [];
}

function SEO({
  description,
  lang = process.env.GATSBY_LANG_ENV == null
    ? "en"
    : process.env.GATSBY_LANG_ENV,
  meta,
  title,
  pageKeywords = null,
  tagKeywords = null,
  pageUrl = null,
  publishedDate = null,
  updateDate = null,
  imgUrl = null,
  imgType = null,
  imgWidth = 1536,
  imgHeight = 768,
}) {
  const videoSrc = require("../components/players/subtitles_love_instr.mp4");
  const baseSeoImg = require("../images/social_cover_opt.jpg");

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            releaseDate
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;

  const publishDate = publishedDate || site.siteMetadata.releaseDate;
  const updateTime =
    updateDate == null ? moment().format() : moment(updateDate).format();

  const seo_img_cover = imgUrl || `https://subtitles.love${baseSeoImg}`;
  const seo_img_type = imgType || "image/jpeg";

  const keywords = pageKeywords || site.siteMetadata.keywords;
  const tKeywords = getKeywords(tagKeywords || site.siteMetadata.keywords);
  const pUrl = pageUrl || site.siteMetadata.siteUrl;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          rel: "shortcut icon",
          type: "image/png",
          href: favIcon,
        },
      ]}
      title={metaTitle}
      meta={[
        //  MAIN SEO
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:site_name`,
          content: "Subtitles.love",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },

        //  BASIC PARAMS
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: pUrl,
        },
        {
          property: `og:locale`,
          content: `en_GB`,
        },
        {
          property: `og:locale:alternate`,
          content: [`en_US, en_CA, en_GB, en_EN, ru_RU`],
        },

        //  VIDEO
        {
          property: `og:video`,
          content: `https://subtitles.love${videoSrc}`,
        },
        {
          property: `og:video:type`,
          content: `video/mp4`,
        },
        {
          property: `og:video:secure_url`,
          content: `https://subtitles.love${videoSrc}`,
        },
        {
          property: `og:video:width`,
          content: "1280",
        },
        {
          property: `og:video:height`,
          content: "720",
        },

        //  PAGE IMAGE
        {
          property: `og:image:url`,
          content: seo_img_cover,
        },
        {
          property: `og:image:secure_url`,
          content: seo_img_cover,
        },
        {
          property: `og:image:type`,
          content: seo_img_type,
        },
        {
          property: `og:image:width`,
          content: imgWidth,
        },
        {
          property: `og:image:height`,
          content: imgHeight,
        },
        {
          property: `og:image:alt`,
          content: metaDescription,
        },

        //  PUBLISH INFO
        {
          property: `article:published_time`,
          content: publishDate,
        },
        {
          property: `article:modified_time`,
          content: updateTime,
        },
        {
          property: `article:author`,
          content: "Subtitles.love",
        },
        {
          property: `article:section`,
          content:
            "AI-powered subtitles editor | Online subtitles editor | Closed video captions editor",
        },
        {
          property: `article:tag`,
          content: tKeywords,
        },

        //  SOCIAL MEDIA
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        { name: "fb:app_id", content: "683173649146296" },
      ].concat(meta)}
    >
      <script>{`(function(w, d, s, p, t) { w.gr = w.gr || function() { w.gr.q = w.gr.q || []; w.gr.q.push(arguments); }; p = d.getElementsByTagName(s)[0]; t = d.createElement(s); t.async = true; t.src = "https://app.getreditus.com/gr.js?_ce=60"; p.parentNode.insertBefore(t, p); })(window, document, "script"); gr("track", "pageview"); `}</script>
      
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
